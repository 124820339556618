export const FETCH_FAMILY_MEMBERS_REQUEST = "FETCH_FAMILY_MEMBERS_REQUEST";
export const FETCH_FAMILY_MEMBERS_SUCCESS = "FETCH_FAMILY_MEMBERS_SUCCESS";
export const FETCH_FAMILY_MEMBERS_FAILURE = "FETCH_FAMILY_MEMBERS_FAILURE";

export const ADD_FAMILY_MEMBER_REQUEST = "ADD_FAMILY_MEMBER_REQUEST";
export const ADD_FAMILY_MEMBER_SUCCESS = "ADD_FAMILY_MEMBER_SUCCESS";
export const ADD_FAMILY_MEMBER_FAILURE = "ADD_FAMILY_MEMBER_FAILURE";

export const UPDATE_FAMILY_MEMBER_REQUEST = "UPDATE_FAMILY_MEMBER_REQUEST";
export const UPDATE_FAMILY_MEMBER_SUCCESS = "UPDATE_FAMILY_MEMBER_SUCCESS";
export const UPDATE_FAMILY_MEMBER_FAILURE = "UPDATE_FAMILY_MEMBER_FAILURE";

export const DELETE_FAMILY_MEMBER_REQUEST = "DELETE_FAMILY_MEMBER_REQUEST";
export const DELETE_FAMILY_MEMBER_SUCCESS = "DELETE_FAMILY_MEMBER_SUCCESS";
export const DELETE_FAMILY_MEMBER_FAILURE = "DELETE_FAMILY_MEMBER_FAILURE";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const SELECT_FAMILY_MEMBER_REQUEST = 'SELECT_FAMILY_MEMBER_REQUEST';
export const SELECT_FAMILY_MEMBER_SUCCESS = 'SELECT_FAMILY_MEMBER_SUCCESS';
export const SELECT_FAMILY_MEMBER_FAILURE = 'SELECT_FAMILY_MEMBER_FAILURE';