import fetch from 'auth/FetchInterceptor';

const BookingService = {};

BookingService.createBooking = function(booking) {
  return fetch({
    url: '/bookings',
    method: 'post',
    data: booking,
  });
};

BookingService.cancelBooking = function(bookingId) {
  return fetch({
    url: `/bookings/${bookingId}`,
    method: 'delete',
  });
};

BookingService.fetchUserBookings = function(date, branch_id) {
  return fetch({
    url: '/bookings',
    method: 'get',
    params: { date, branch_id },
  });
};

BookingService.fetchUserBookedTimes = function() {
  return fetch({
    url: '/booked-times',
    method: 'get',
  });
};

export default BookingService;
