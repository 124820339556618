import { FETCH_SAMPLE_COLLECTED_ORDERS_FAILURE, FETCH_SAMPLE_COLLECTED_ORDERS_REQUEST, FETCH_SAMPLE_COLLECTED_ORDERS_SUCCESS } from "constants/OrderStatus/SampleCollectedOrders"

const initState = {
    loadingSampleCollectedOrders: false,
    sampleCollectedOrders: [],
    errorMessage: "",
}

const SampleCollectedOrders = (state = initState, action) => {

    switch (action.type) {
        case FETCH_SAMPLE_COLLECTED_ORDERS_REQUEST:
            return {
                ...state,
                loadingSampleCollectedOrders: true,
            }

        case FETCH_SAMPLE_COLLECTED_ORDERS_SUCCESS:
            return {
                ...state,
                loadingSampleCollectedOrders: false,
                sampleCollectedOrders: action.payload
            }

        case FETCH_SAMPLE_COLLECTED_ORDERS_FAILURE:
            return {
                ...state,
                loadingSampleCollectedOrders: false,
                errorMessage: action.payload
            }
        default:
            return state
    }
}

export default SampleCollectedOrders