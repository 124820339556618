import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import firebaseConfig from 'configs/FirebaseConfig';
import firebaseConfigDr from '../../src/views/doctors-app-views/drs-chat-room/firebase';
import { doesThisUserIsDoctor, getCurrentRole } from 'utils/CurrentUserUtil';

// firebase.initializeApp(doesThisUserIsDoctor() ? firebaseConfigDr : firebaseConfig);
firebase.initializeApp(firebaseConfig);


// firebase.initializeApp(firebaseConfig);

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth();
const currentUser = auth.currentUser
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();

const fireBaseStorageRef = firebase.storage().ref();

export {
	db,
	auth,
	currentUser,
	googleAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider,
	githubAuthProvider,
	fireBaseStorageRef
};