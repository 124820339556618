import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { NewTestTable } from './newTestTable';

export const NewTest = ({ testDetail, history, setClicked}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);


    const showModal = () => {
      setClicked(false)
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
        setIsModalVisible(false);
        history.push('/doctor/order/new')

    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
  
    return (
      <div>
        <div 
          style={{ cursor: 'pointer', color: '#1890ff' }} 
          onClick={showModal}
        >
          {testDetail.name}
        </div>
          <Modal 
              width={1000} 
              title="Test Details" 
              visible={isModalVisible} 
              onOk={handleOk} 
              onCancel={handleCancel}
              okText='Place Order'
          >
          <NewTestTable testDetail={testDetail}/>
        </Modal>
      </div>
    );
  };