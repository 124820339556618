import { FETCH_AGENCY_DOCTORS_FAILURE, FETCH_AGENCY_DOCTORS_REQUEST, FETCH_AGENCY_DOCTORS_SUCCESS } from "constants/FetchAgencyDoctors";


export const fetchAgencyDoctorsRequest = () => ({
    type: FETCH_AGENCY_DOCTORS_REQUEST
});

export const fetchAgencyDoctorsSuccess = (payload) => ({
    type: FETCH_AGENCY_DOCTORS_SUCCESS,
    payload: payload
});

export const fetchAgencyDoctorsFailure = (payload) => ({
    type: FETCH_AGENCY_DOCTORS_FAILURE,
    payload: payload
});

