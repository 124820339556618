import fetch from "auth/FetchInterceptor";

const fetchAgencyDoctorsService = {};

fetchAgencyDoctorsService.get = function () {
    return fetch({
      url: "/agency-doctors",
      method: "get",
    });
  };

export default fetchAgencyDoctorsService;