import fetch from "auth/FetchInterceptor";

const StatResults = {};

StatResults.getStatResults = function (payload) {
    return fetch({
        url: `/reports/patientresult-stat?
        startdate=${payload.startDate}&
        enddate=${payload.endDate}&
        agency=${payload.agency}&
        department=null`,
        method: "get",
    });
};

export default StatResults;
