import { FETCH_REDRAW_SAMPLES_REQUEST, FETCH_REDRAW_SAMPLES_SUCCESS, FETCH_REDRAW_SAMPLES_FAILURE } from "constants/OrderStatus/RedrawSamples"

const initState = {
    loadingRedrawSamples: false,
    redrawSamples: [],
    errorMessage: "",
}

const RedrawSamples = (state = initState, action) => {
    switch (action.type) {
        case FETCH_REDRAW_SAMPLES_REQUEST:
            return {
                ...state,
                loadingRedrawSamples: true,
            }

        case FETCH_REDRAW_SAMPLES_SUCCESS:
            return {
                ...state,
                loadingRedrawSamples: false,
                redrawSamples: action.payload
            }

        case FETCH_REDRAW_SAMPLES_FAILURE:
            return {
                ...state,
                loadingRedrawSamples: false,
                errorMessage: action.payload
            }
        default:
            return state
    }
}

export default RedrawSamples