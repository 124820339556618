import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
  FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  FETCH_EXISTING_PATIENT_REQUEST,
} from "constants/PatientTestResultHistory";
import {
  fetchPatientTestResultHistoryFailure,
  fetchPatientTestResultHistorySuccess,
  fetchPatientFailure,
  fetchPatientSuccess
  // fetchPatientTestResultHistorySuccess,
  // fetchPatientTestResultHistoryFailure,
} from "redux/actions";

import PatientTestResultHistoryService from "services/PatientTestResultHistoryService";



const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

export function* onFetchPatientTestResultHistory() {
  yield takeLatest(FETCH_PATIENTTESTRESULTHISTORY_REQUEST, function* (payload) {
    try {
      const result = yield call(PatientTestResultHistoryService.index, payload.payload);
      if (result.status) {
        yield put(fetchPatientTestResultHistorySuccess(result.data));
      } else {
        yield put(fetchPatientTestResultHistoryFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPatientTestResultHistoryFailure(error.message));
    }
  });
}

export function* onFetchExistingPatients() {
  yield takeLatest(
    FETCH_EXISTING_PATIENT_REQUEST,
    function* ({ payload }) {
      try {
        // yield call(delay, 3000);
        // if (payload.patientid == "LB18138230643" || payload.sampleid == "18138230643" || payload.fullname == "GENET MAMO ABERA" || payload.phonenumber == "0911892589") {
        //   yield put(fetchPatientSuccess([{
        //     "abnFullname": "Genet",
        //     "abnSampleId": "18138230643",
        //     "abnDateOfBirth": "2022/12/12",
        //     "abnSex": "F"

        //   }]));
        // } else {
        //   message.warning("Not found");
        //   yield put(fetchPatientSuccess([]));
        // }

        const result = yield call(PatientTestResultHistoryService.searchpatient, payload);

        if (result.status === true && (result.data.length === 0)) {
          message.warning("Not found");
          yield put(fetchPatientFailure("Not found"));
        }
        if (result.status === true && (result.data.length > 0)) {
          yield put(fetchPatientSuccess(result.data));
        } else {
          // message.warning("Something went wrong");
          yield put(fetchPatientFailure("Something went wrong"));
        }
      } catch (error) {
        yield put(fetchPatientFailure(error.message));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(onFetchPatientTestResultHistory),
    fork(onFetchExistingPatients),
  ]);
}
