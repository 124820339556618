import { ADD_DOCTORS_BY_CLIENT_COMPANY_FAILURE, ADD_DOCTORS_BY_CLIENT_COMPANY_REQUEST, ADD_DOCTORS_BY_CLIENT_COMPANY_SUCCESS } from "constants/addDoctorsByClinetCompany";

const initialState = {
    loading: false,
    doctors: [],
    error: null,
  };

const addDoctorsByClientCompanyReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_DOCTORS_BY_CLIENT_COMPANY_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case ADD_DOCTORS_BY_CLIENT_COMPANY_SUCCESS:
        return {
          ...state,
          loading: false,
          doctors: action.payload,
        };
      case ADD_DOCTORS_BY_CLIENT_COMPANY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default addDoctorsByClientCompanyReducer;