export const Amharic = {
    "home" : "መግብያ",
    "upload" : "ይላኩ",
    "start_upload" : "መላክ ይጀምሩ",
    "uploading" : "አየላከ ነው",
    "contact" : "ኣገናኝ",
    "abbrev" : "አጭር",
    "speed_dial" : "የ ፍጥነት መደወያ",
    "fee_schedule" : "የ ክፍያ መርሃ ግብር",
    "credit_allowed" : "የተፈቀደ ክረዲት",
    "allowed_for_credit" : "ለ ክረዲት ተፈቅዱዋል",
    "tin_or_arn" : "TIN ወይም ARN",
    "currency" : "ምንዛሪ",
    "npid" : "NPID",
    "user_management": "የተጠቃሚ አስተዳደር",
    "user_list": "የተጠቃሚ ዝርዝር",
    "change_password": "የይለፍ ቃል ቀይር",
    "role": "አስተዳደር",
    "user": "ተጠቃሚዎች",
    "departments": "ክፍሎች",
    "test_menu" : "የሙከራ ምናሌ",
    "select_agency" : "ኤጀንሲ ይምረጡ",
    "select_department" : "ክፍል ይምረጡ",
    "edit_department" : "ክፍል አርትዕ",
    "edit_agency" : "ኤጀንሲ አርትዕ",
    "agency_detail" : "ኤጀንሲ ዝርዝር",
    "select_role" : "ሚና ይምረጡ",
    "select_location" : "ቦታ ይምረጡ",
    "config" : "አዋቅር",
    "dashboard" : "ዳሽቦርድ",
    "agency_definition": "የአገናኝ መብት",
    "discontinued_test_results": "የተቋረጡ_ውጤቶች",
    "assign_test_to_agency": "ለኤጀንሲው ሙከራ መመደብ",
    "branch_look_up": "ቅርንጫፍ ወደ ላይ ይመልከቱ",
    "test_definition": "የሙከራ ፍቺ",
    "core_lab": "ኮር ላብራቶሪ",
    "tb_micro_pending": "ቲቢ/ማይክሮ በመጠባበቅ ላይ",
    "place_order": "ምርመራ ማዘዣ",
    "pick_up_request": "የናሙና መላክ ጥያቄ",
    "time_line": "የጊዜ መለያ",
    "order_status": "የምርመራ ሁኔታ",
    "all": "ሁሉም",
    "results": "ውጤቶች",
    "completed": "ተጠናቋል",
    "incomplete": "ያልተሟላ",
    "micro_patho_result": "የማይክሮ ውጤት",
    "comming_soon": "በቅርብ ቀን",
    "pannic_results": "የፓናይክ ውጤቶች",
    "confidential_results": "ሚስጥራዊ ውጤቶች",
    "commulative_sum_report": "ጠቅላላ ድምር ሪፖርት",
    "faulty_pdf": "የተሳሳተ pdf",
    "upload_results": "ውጤት መላክ ይጀምሩ",
    "upload_csv_results": "የcsv ውጤት መላክ ይጀምሩ",
    "upload_financial_report": "የፋይናንሺያል ሪፖርትን ጫን",
    "corrected_results": "የተስተካከሉ ውጤቶች",
    "pending": "በመጠባበቅ ላይ",
    "approved": "የጸድቀ",
    "declined": "ተቀባይነት የሌለው",
    "daily_released_tests": "በየቀኑ የሚለቀቁ ሙከራዎች",
    "single_daily_released_tests": "በየቀኑ የሚለቀቁ ሙከራዎች",
    "daily_department_released_tests": "ዕለታዊ ዲፓርትመንት የተለቀቁ ሙከራዎች",
    "you_must_select_agency" : "ኤጀንሲ መምረጥ አለብህ",
    "choose_agency_first" : "መጀመሪያ ኤጀንሲን ይምረጡ",
    "order_to_be_sent": "ለሚላክ ትዕዛዝ",
    "stat_orders": "ፈጣን ምርመራ",
    "referal_orders": "የማጣቀሻ ትዕዛዞች",
    "orderable" : "ሊታዘዝ የሚችል",
    "none_orderable" : "የማይታዘዝ",
    "actions" : "ድርጊቶች",
    "available" : 'ይገኛል',
    "not_available" : 'አይገኝም',
    "referable" : "የሚላክ",
    "sample_processing_in_lab": "በቤተ ሙከራ ውስጥ የናሙና ሂደት",
    "rejected": "ተቀባይነት የሌለው",
    "tests_per_technician": "በአንድ ቴክኒሻን ሙከራዎች",
    "daily_financial_summary": "የቀኑ የፋይናንሺያል ማጠቃለያ",
    "specimen_tracking": "የናሙና ክትትል",
    "top_branches_for_walkin_customers": "ለእግርኛ ደንበኞች ከፍተኛ ቅርንጫፎች",
    "released_after_tat": "የዘገዩ ሙከራዎች",
    "tat_routine_report": "የቲኤቲ መደበኛ ሪፖርት",
    "released_pannels_for_icl": "የተለቀቁ ፓነሎች ለICL",
    "top_agencies": "ከፍተኛ ኤጀንሲዎች",
    "top_tests": "ከፍተኛ ምርመራዎች",
    "top_orders": "አብዛኞቹ ትዕዛዞች",
    "credit_invoice": "የክሬዲት ደረሰኝ",
    "financial":"የበጀት ሪፖርት",
    "tat_approaching": "TAT ቀረቤታ",
    "submit" : "ላክ",
    "test_results" : "የምርመራ ውጤቶች",
    "released_tests" : "የተለቀቁ ምርመራዎች",
    "pending_tests" : "ያልተለቀቁ ምርመራዎች",
    "panic_tests" : "የሽብር ምርመራዎች",
    "uncommunicated_panic" : "ያልተዘገበ ፓኒክ",
    "average_tat" : "አማካይ TAT",
    "average_release_time" : "አማካይ የመልቀቂያ ጊዜ",
    "average_delayed_tests" : "አማካይ የዘገየ ጊዜ",
    "total_orders" : "ጠቅላላ ትዕዛዞች",
    "total_income" : "ጠቅላላ ገቢ",
    "total_patients" : "ጠቅላላ ታካሚዎች",
    "order_statistics" : "የትዕዛዝ ስታቲስቲክስ",
    "agency" : "ኤጀንሲ",
    "walkin" : "ደንበኛ",
    "payment" : "ጠቅላላ ክፍያ",
    "view_detail" : "ዝርዝር እይታ",
    "detail" : "ዝርዝር",
    "edit" : "ቀይር",
    "delete" : "ሰርዝ",
    "sample_id" : "የናሙና መታወቂያ",
    "name" : "ስም",
    "agency_id" : "የኤጀንሲው መታወቂያ",
    "agency_number" : "የ ኤጀንሲው ቁጥር",
    "status" : "ሁኔታ",
    "apply_filter" : 'ማጣሪያ ይተግብሩ',
    "reset" : "ዳግም አስጀምር",
    "patient_id" : "የታካሚ መታወቂያ",
    "email" : "ኢሜይል",
    "firstname" : "የመጀመሪያ ስም",
    "lastname" : "የአያት ስም",
    "middlename" : "የአባት ስም",
    "sex" : "ጾታ",
    "date_of_birth" : "የተወለደበት ቀን",
    "phone_number" : "ስልክ ቁጥር",
    "location" : "አካባቢ",
    "username" : "ዩዘርኔም",
    "active" : "በመስራት ላይ ያሉ",
    "inactive" : "መስራት ያቆሙ",
    "user_detail" : "ተጨማሪ መረጃ",
    "search" : "ፈልግ",
    "filter" : "ኣጣራ",
    "am" : "ኣም",
    "yes" : "ኣዎ",
    "no" : "ኣይ",
    "notification" : "ማሳወቅያዎች",
    "add_new_user" : "አዲስ ተጠቃሚ ያክሉ",
    "add_new_department" : "አዲስ ክፍሎች ያክሉ",
    "add_client_company" : "ኤጀንሲ ይጨምሩ",
    "edit_user" : "ተጠቃሚን አርትዕ",
    "dark" : "ጨለማ",
    "light" : "ነጭ",
    "cashier_location" : "ገንዘብ ተቀባይ ቦታ",
    "city" : "ከተማ",
    "cashier_code" : "ገንዘብ ተቀባይ ኮድ",
    "code" : "ኮድ",
    "password" : "የይለፍ ቃል",
    "is_icl_user" : "የአይሲኤል አባል ነው",
    "are_you_sure" : "እርግጠኛ ነዎት ይህን ለ መሰረዝ?",
    "copyright" : "የቅጂ መብት",
    "icl_all_right_reserved" : " መብቱ በህግ የተጠበቀ ነው",
    "add_role": "ሚና ይጨምሩ",
    "permissions": "ፈቃድ",
    "add_new_role": "አዲስ ሚና ይጨምሩ",
    "select_permission": "ፈቃድ ይምረጡ",
    "enter_name": "ስም ያስገቡ",
    "edit_permission_for": "ሚና መቀየር ለ",
    "role_detail": "ሚና ዝርዝር",
    "test_name" : "የምርመራ ስም",
    "referral" : "ሪፈር",
    "success" : "ተሳክቶአል",
    "error" : "ስህተት",
    "temporarly_discontinued_test" : "ለጊዜው የተቋረጠ ምርምራ",
    "sample_type" : "የናሙና አይነት",
    "sample_volume" : "የናሙና መጠን",
    "sample_container" : "ናሙና መያዣ",
    "price" : "ዋጋ",
    "non_orderable" : "የማይታዘዝ",
    "add_new_test" : "አዲስ ምርመራ ጨምር",
    "test_schedule" : "የምርመራ መርሃ ግብር",
    "update_stat_and_urgent" : "አስቸኳይ ምርመራ ዋጋ ይቅይሩ",
    "stat_price" : "ስታት ዋጋ",
    "urgent_price" : "አስቸኳይ ምርመራ ዋጋ",
    "field_is_required" : "ይህ ፊልድ ያስፈልጋል",
    "updating" : "በመቀየር/በመለወጥ ላይ",
    "update" : "ለውጥ",
    "enable_edit" : "መለወጥ እንዲቻል አርግ",
    "test_abbreviation" : "የምርመራ አህጽሮተ ቃል",
    "test_full_name" : "የምርመራ ሙሉ ስም",
    "number_of_tests" : "የምርመራ ብዛት",
    "department_name" : "መምሪያው",
    "price_etb_agency_a" : "የዋጋ በብር ኤጀንሲ A",
    "price_etb_agency_b" : "የዋጋ በብር ኤጀንሲ B",
    "price_etb_agency_c" : "የዋጋ በብር ኤጀንሲ C",
    "price_etb_agency_d" : "የዋጋ በብር ኤጀንሲ D",
    "price_etb_agency_e" : "የዋጋ በብር ኤጀንሲ E",
    "price_etb_agency_f" : "የዋጋ በብር ኤጀንሲ F",
    "price_etb_agency_g" : "የዋጋ በብር ኤጀንሲ G",
    "price_etb_agency_h" : "የዋጋ በብር ኤጀንሲ H",
    "price_usd_agency_a" : "የዋጋ በዳላር ኤጀንሲ A",
    "price_usd_agency_b" : "የዋጋ በዳላር ኤጀንሲ B",
    "price_usd_agency_c" : "የዋጋ በዳላር ኤጀንሲ C",
    "price_usd_agency_d" : "የዋጋ በዳላር ኤጀንሲ D",
    "price_usd_agency_e" : "የዋጋ በዳላር ኤጀንሲ E",
    "price_usd_agency_f" : "የዋጋ በዳላር ኤጀንሲ F",
    "price_usd_agency_g" : "የዋጋ በዳላር ኤጀንሲ G",
    "tat_urgent" : "tat አስቸኳይ",
    "tat_stat" : "ፈጣን ምርመራ ያስፈልገዋል",
    "ignorable_tat" : "የማይታወቅ ታት/TAT",
    "fake_department" : "የውሸት ክፍል",
    "inperson_delivery" : "በአካል ማድረስ",
    "currently_available" : "በአሁኑ ጊዜ ይገኛል።",
    "panel_number" : "የፓነል ቁጥር",
    "charge_code" : "የክፍያ ኮድ",
    "test_catagory" : "የምርመራ መደብ",
    "test_sub_category" : "የሙከራ ንዑስ ምድብ",
    "test_description" : "የሙከራ መግለጫ",
    "price_etb" : "ዋጋ በብር",
    "price_usd" : "ዋጋ በዳላር",
    "standard_test_name" : "መደበኛ የምርመራ ስም",
    "standard_department_name" : "መደበኛ የመምሪያው ስም",
    "standard_abbreviation" : "መደበኛ ምህጻረ ቃል",
    "test_methodology" : "የምርመራ ዘዴ",
    "estimated_result_time_in_hour" : "የሚገመተው የውጤት ጊዜ በሰዓት",
    "test_done_abroad" : "ምርመራው ውጭ ነው ሜከናወነው",
    "is_ihc_est" : "IHC ምርመራ ነው",
    "constant_only_for_pathology" : "ቋሚ (ለፓቶሎጂ ብቻ)",
    "walking_customer_price_usd" : "የእግር ጉዞ ደንበኛ ዋጋ (USD) ዳላር",
    "walking_customer_price_etb" : "የእግር ጉዞ ደንበኛ ዋጋ (ETB) ብር",
    "are_you_sure_delete_this_test" : "እርግጠኛ ነዎት ይህን ምርመራ መሰረዝ?",
    "department_code" : "የመምሪያው ኮድ",
    "has_custom_data" : "ብጁ ውሂብ አለው።",
    "in_person_delivery" : "በሰው ማድረስ",
    "icl_branch" : "icl ቅርንጫፍ",
    "not_icl_branch" : "አይደለም icl ቅርንጫፍ",
    "parent" : "parent",
    "branch_name" : "የቅርንጫፉ ስም",
    "clear" : "ኣጽዳ",
    "signout" : "ዘግተህ ዉጣ",
    "main": "ዋና ገፅ",
    "additional_info": "ተጨማሪ መረጃ",
    "insurance": "አስተዳደር",
    "my_lab": "የእኔ ላብራቶሪ",
    "pathology": "ፓቶሎጂ",
    "mrn_no": "የታካሚ መታወቂያ ቁጥር",
    "first_name": "የመጀመሪያ ስም",
    "middle_name": "የአባት ስም",
    "last_name": "የአያት ስም",
    "primary_doctor": "ዋና ሀኪም",
    "secondary_doctor": "ሁለተኛ ሀኪም",
    "test_performed_lab": "የምርመራዉ ቦታ",
    "select_lab": "ላቦራቶሪ ምሪጥ",
    "phone_alt": "ሁለተኛ ስልክ ቁጥር",
    "region": "ክልል",
    "street": "መንገድ",
    "passport_no": "የፓስፖርት ቁጥር",
    "select_insurance": "ኢንሹራንስ ምረጥ",
    "member_id": "የአባልነተ ID",
    "did_you_have_a_meal_today": "ዛሬ ምግብ በልተዋል?",
    "have_you_exercised_today": "ዛሬ አንቅስቃሴ ኣድረገዋል?",
    "exercise_regularly": "በየወቅቱ አንቅስቃሴ ያደርጋሉ?",
    "how_many_times_do_you_excesise_per_week": "በሳምንት ምን ያህል ጊዜ አንቅስቃሴ ያረጋሉ?",
    "how_long_excercise": "ሰንት ደይቃ ነው አንቅስቃሴው?",
    "excercise_grade": "የንቅስቃሴዉ ክብደት?",
    "feel_stressed": "የጪንቅት ስሜት ይሠማዎታል?",
    "professional_help": "ጭንቀትን ለመቆጣጠር የባለሙያ እርዳታ ጠይቀው ያውቃሉ?",
    "sleep_duration": "በቀን ስንት ሰዓት ይተኛሉ?",
    "caffine_consumption_daily": "በየቀኑ ምን ያህል ኩባያ ቡና ወይም ሻይ ይጠቀማሉ?",
    "daily_water_intake": "በየቀኑ ምን ያህል ሊትር ውሃ ይጠቀማሉ?",
    "daily_fruits_intake": "በቀን ከ3-5 ጊዜ አትክልትና ፍራፍሬ እያገኙ ነው?",
    "usually": "በተለምዶ",
    "smoke_status": "ያጨሳሉ?",
    "somoke_other_than_cigar": "ከሲጋራ ሌላ ያጨሳሉ?",
    "alcohol_consumption": "ኣልኮሆል ይጠቀማሉ?",
    "work_nature": "የሥራ ዓይነት",
    "residence": "የመኖሪያ ቦታ?",
    "travel_status": "የጉዞ ሁኔታ?",
    "dm_family_history": "የስኳር በሽታ የቤተሰብ ታሪክ አለዎት?",
    "htn_family_history": "የደም ግፊት የቤተሰብ ታሪክ አለዎት?",
    "cancer_family_history": "የካንሰር በሽታ የቤተሰብ ታሪክ አለዎት?",
    "education_level": "የትምህርት ደረጃ",
    "pregnancy": "እርግዝና አለ?",
    "breast_feeding_status": "ጡት እያጠቡ ነው?",
    "contraception_use": "የሆርሞን የወሊድ መከላከያዎችን ይጠቀማሉ?",
    "less_than": "ያነሰ ከ",
    "minutes": "ደቂቃ",
    "more": "በላይ",
    "and": "እና",
    "simple": "ቀላል",
    "moderate": "መካከለኛ",
    "vigorous": "ከባድ",
    "hours": "ሰዓት",
    "eat_out": "ወጪ የመገባሉ?",
    "cook_at_home": "የቤት ምግብ ይጠቀማሉ?",
    "work_nature_1": "መቀመጥ የሚበዛበት የቢሮ ሰራ",
    "work_nature_2": "አንቅስቃሴ የሚበዛበት የቢሮ ሰራ",
    "work_nature_3": "መቆም ሚበዛበት አካላዊ ሥራ",
    "urban": "ከተማ",
    "rural": "ገጠር",
    "suburban": "የከተማ ዳርቻ",
    "travel_by_private": "የግል መኪና",
    "travel_by_public": "የህዝብ ማጓጓዣ",
    "walk": "እግረኛ",
    "read_write": "መፃፋ እና ማንበብ",
    "secondary_school": "ሁለተኛ ደረጃ",
    "illiterate": "ያልተማረ",
    "primary_school": "የመጀመሪያ ደረጃ",
    "college_and_above": "ኮሌጅ እና ከፍተኛ ደረጃ",
    "search_pannel": "ፓነሎችን በስም ፣ በኮድ ፈልግ…",
    "search_test_pannels": "የምርመራ ፓነሎችን ፈልግ",
    "clinical_info": "የክሊኒክ መረጃ",
    "relevant_history": "ኣስፈላጊ ታሪክ",
    "relevant_image": "ኣስፈላጊ ምስል",
    "relevant_lab": "ኣስፈላጊ ላቦራቶርኢ ወጤት",
    "enter_relevant_info": "ኣስፈላጊ መረጃ ያስገቡ",
    "enter_relevant_lab_work_info": "ኣስፈላጊ የምርመራ መረጃ ያስገቡ",
    "enter_relevant_image_info": "ኣስፈላጊ ምስል መረጃ ያስገቡ",
    "specimen_class": "የናሙና ኣይነት",
    "biopsy_type": "የባዮፕስይ አይነት",
    "action": "ድርጊት",
    "urgent_order": "አስቸኳይ ትዕዛዝ",
    "search_here": "እዚህ ፈልግ",
    "patient_search": "የታካሚ ፈልግ",
    "test_code": "የምርመራ ኮድ",
    "departmanet_name": "የመምሪያው ስም",
    "default_price": "ነባሪ ዋጋ",
    "schedule": "የምርመራ መርሃ ግብር",
    "add_to_table": "ወደ ዝርዝር ጨምር",
    "male": "ወንድ",
    "female": "ሴት",
    "order_summary": "የትዕዛዝ ጥቅልልት",
    "full_name": "ሙሉ ስም",
    "total_number_of_tests": "የምርመራ ብዛት",
    "extra_fee": "ተጨማሪ ዋጋ",
    "total_price": "ጠቅላላ ዋጋ",
    "list_of_sample_container": "ናሙና መያዣ ዝርዝር",
    "show_printable_order_summary": "ሊታተም የሚችል ትዕዛዝ ማጠቃለያ አሳይ",
    "save": "አስቀምጥ",
    "order": "አዘዝ",
    "cancel": "ሰርዝ",
    "costs": "",
    "provide_additional_patho_info":"የፓቶሎጂ ምርመራ ተመርጧል. ተጨማሪ የፓቶሎጂ መረጃ መሙላት ያስፈልግዎታል",
    "field_required": "ይህ ፊልድ ያስፈልጋል",
    "okay": "እሺ",
    "transaction_response": "የግብየት መረጃ",
    "cashier_summary": "ለገንዘብ ተቀባይ የትእዛዝ ማጠቃለያ",
    "confirm": "አረጋገጥ",
    "print_cash_receipt": "የገንዘብ ደረሰኝ አሳይ",
    "pt_agreed": "ሕመምተኛው ተስማምቷል",
    "reciept_no": "የደረሰኝ ቁጥር",
    "add_new_branch" : "አዲስ ቅርንጫፍ ይጨምሩ",
    "concatenated_code" : "የተቀናጀ ኮድ",
    "branch_code" : "የቅርንጫፍ ኮድ",
    "branch_description" : "የቅርንጫፍ መግለጫ",
    "telephone_1" : "ስልክ 1",
    'telephone_2' : "ስልክ 2",
    "sample_collection_only" : "የናሙና ስብስብ ብቻ",
    "covid_test_provided" : "የኮቪድ ሙከራ ቀርቧል",
    "wellness_test_provided" : "የጤንነት ፈተና ቀርቧል",
    "speed_dial_provider" : "የፍጥነት መደወያ አቅራቢ",
    "open_on_holiday" : "በበዓል ክፍት ነው",
    "open_every_time" : 'በእያንዳንዱ ጊዜ ክፍት ነው',
    "visible_to_public" : "ለሕዝብ የሚታይ",
    "allowed_for_coupon" : "ለኩፖን ይፈቀዳል",
    "edit_branch_look_up" : "የቅርንጫፍ ፍለጋን ያርትዑ",
    "add_branch_look_up" : "የቅርንጫፍ ፍለጋን ይጨምሩ",
    "view_branch_look_up_detail" : "የቅርንጫፍ ፍለጋ ዝርዝር",
    "panel_summary" : "የፓነል ማጠቃለያ",
    "are_you_sure_this_panels_are_for" : "እርግጠኛ ነዎት  እነዚያ ፓነሎች ለ ",
    "other" : 'ሌላ',
    "send_pickup_request" : "የመልቀሚያ ጥያቄን ላክ",
    "sending" : "እየላከ ነው",
    "search_historical_record" : "ታሪካዊ ውሂብን ይፈልጉ",
    "fullname" : "ሙሉ ስም",
    "cardnumber" : "የ ካርድ ቁጥር",
    "urgency" : "አስቸኳይ",
    "age" : "ዕድሜ",
    "panic_result" : "የድንጋጤ ውጤት",
    "spolied_samples" : "የተበላሹ ናሙናዎች",
    "approval_status" : "የማጽደቅ ሁኔታ",
    "ordered_time" : "የታዘዘ ጊዜ",
    "downloading" : "በማውረድ ላይ",
    "download_existing" : "የነበረዉን ያውርዱ",
    "only_csv_files_are_allowed" : "csv ብቻ ነዉ ሚፈቀደዉ",
    "select_category" : "ምድብ ይምረጡ",
    "month" : "ወር",
    "add_to_list" : "ወደ ዝርዝር ያክሉ",
    "select_file" : "ፋይል ይምረጡ",
    "total_tests" : "ጠቅላላ ፈተናዎች",
    "total_discrepancy" : "አጠቃላይ ልዩነት",
    "received_time" : "የተቀበለው ጊዜ",
    "released_time" : "የተለቀቀበት ጊዜ",
    "pdf" : "PDF",
    "flag" : "ማስጠንቀቂያ",
    "new_time" : "አዲስ ጊዜ",
    "discrepancy_detail" : "የልዩነት ዝርዝር",
    "technician" : "ቴክኒሻን",
    "inserted_date" : "የገባበት ቀን",
    "test_count" : "የፈተና ብዛት",
    "type_of_report" : 'የሪፖርት ዓይነት',
    "invoice_summary" : "የክፍያ መጠየቂያ ማጠቃለያ",
    "invoice_attachment" : "የክፍያ መጠየቂያ ዓባሪዎች",
    "statement_of_account" : "የሂሳብ መግለጫ",
    "only_pdf_file_is_allowed" : "only pdf file is allowed",
    "cashier_name" : "ገንዘብ ተቀባይ ስም",
    "fs_number" : "fs ቁጥር",
    "payment_type" : "የክፍያ ዓይነት",
    "date_time" : "የቀን ጊዜ",
    "export" : "ኣውርድ",
    "sample_tube" : "የናሙና ቱቦ",
    "comment" : "አስተያየት",
    "remark" : "አስተያየት",
    "enter_comment" : "አስተያየት አስገባ",
    "phlebotomy_comment" : "ፍሌቦቶሚ አስተያየት",
    "draw_location" : "የተቀዳበት ቦታ",
    "uploaded_at" : "የተላከበት ሰኣት",
    "upload_by" : "በማን ተላከ",
    "media_type" : "የሚዲያ ዓይነት",
    "select_media_type" : " የሚዲያ ዓይነት ይምረጡ",
    "only_jpg_jpeg_png_and_pdf_file_is_allowed" : "የተፈቀደው jpg jpeg png እና pdf ፋይል ብቻ ነው",
    "logistics" : "ሎጂስቲክስ",
	"selectcashier" : "ካሸር ምረጥ",
    "user_type" :"የተጠቃሚ አይነት",
	"specimen_managment" : "ስፔስመን ማናግመንት",
    "recieved_time": "የተቀበሉበት ሰአት"
}