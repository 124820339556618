import { FETCH_AGENCY_DOCTORS_FAILURE, FETCH_AGENCY_DOCTORS_REQUEST, FETCH_AGENCY_DOCTORS_SUCCESS } from "constants/FetchAgencyDoctors";

const initialState = {
    agencyDoctors: [],
    drAgency: null,
    loading: false,
    error: null
  };

const agencyDoctorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AGENCY_DOCTORS_REQUEST:
            return { ...state, loading: true };
        case FETCH_AGENCY_DOCTORS_SUCCESS:
            return { ...state, agencyDoctors: action.payload, loading: false };
        case FETCH_AGENCY_DOCTORS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default agencyDoctorsReducer;