import { all, fork, put, takeLatest } from "@redux-saga/core/effects";

import {
    FETCH_ALL_NOTIFICATIONS_REQUEST,
    UPDATE_NOTIFICAITON_REQUEST
} from "constants/Notifications"

import {
  fetchAllNotificationsFailure,
  fetchAllNotificationsSuccess,
  updateNotificationFailure,
  updateNotificationSuccess,
} from "redux/actions";
//import NotificationsService from "services/Notifications";

export function* onFetchAllNotifications() {
  yield takeLatest(FETCH_ALL_NOTIFICATIONS_REQUEST, function* () {
    try {
       yield put(fetchAllNotificationsSuccess([
        {tests : ["sometests"]},
        {tests : ["sometests"]},
        {tests : ["sometests"]},
        {tests : ["sometests"]},
        {tests : ["sometests"]},
        {doctor: ["Result resleased for patient name .."]},
      ]));
       //const result = yield call(NotificationsService.get);
       //console.log(result)
    //   if (result.status) {
    //     yield put(fetchAllNotificationsSuccess(result.data));
    //   } else {
    //     yield put(fetchAllNotificationsFailure(result.message));
    //   }
    } catch (error) {
      yield put(fetchAllNotificationsFailure(error.message));
    }
  });
}

export function* onUpdateNotifications() {
  yield takeLatest(UPDATE_NOTIFICAITON_REQUEST, function* ({ payload }) {
    try {
        
      yield put(updateNotificationSuccess([]));
      // const result = yield call(NotificationsService.update, payload);
      // if (result.status) {
      //   yield put(updateNotificationSuccess(result.data));
      // } else {
      //   yield put(updateNotificationFailure(result.message));
      // }
    } catch (error) {
      yield put(updateNotificationFailure(error.message));
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(onFetchAllNotifications),
    fork(onUpdateNotifications),
  ]);
}
