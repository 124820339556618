import fetch from "auth/FetchInterceptor";

const SampleCollectedOrders = {};

SampleCollectedOrders.getSampleCollectedOrders = function (payload) {
    return fetch({
        url: `/reports/sample-collected-orders?startdate=
            ${payload.startDate}&enddate=
            ${payload.endDate}&agency=
            ${payload.agency}`,
        method: "get",
    });
};

export default SampleCollectedOrders;
