import { FETCH_TIME_SLOTS_FAILURE, FETCH_TIME_SLOTS_REQUEST, FETCH_TIME_SLOTS_SUCCESS } from "constants/TimeSlot";

export const fetchTimeSlotsRequest = (date, branch_id, timezone) => {
  if (!branch_id) {
    return fetchTimeSlotsFailure("Please select a service location before choosing a service date!");
  }
  return {
    type: FETCH_TIME_SLOTS_REQUEST,
    payload: { date, branch_id, timezone },
  };
};
  
  export const fetchTimeSlotsSuccess = (slots) => ({
    type: FETCH_TIME_SLOTS_SUCCESS,
    payload: slots,
  });
  
  export const fetchTimeSlotsFailure = (error) => ({
    type: FETCH_TIME_SLOTS_FAILURE,
    payload: error,
  });