import {
    FETCH_FAMILY_MEMBERS_REQUEST,
    FETCH_FAMILY_MEMBERS_SUCCESS,
    FETCH_FAMILY_MEMBERS_FAILURE,
    ADD_FAMILY_MEMBER_REQUEST,
    ADD_FAMILY_MEMBER_SUCCESS,
    ADD_FAMILY_MEMBER_FAILURE,
    UPDATE_FAMILY_MEMBER_REQUEST,
    UPDATE_FAMILY_MEMBER_SUCCESS,
    UPDATE_FAMILY_MEMBER_FAILURE,
    DELETE_FAMILY_MEMBER_REQUEST,
    DELETE_FAMILY_MEMBER_SUCCESS,
    DELETE_FAMILY_MEMBER_FAILURE,
    SELECT_FAMILY_MEMBER_REQUEST,
    SELECT_FAMILY_MEMBER_SUCCESS,
    SELECT_FAMILY_MEMBER_FAILURE,
    UPLOAD_IMAGE_REQUEST,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,
  
  } from 'constants/FamilyMember';
  
  export const fetchFamilyMembersRequest = () => ({
    type: FETCH_FAMILY_MEMBERS_REQUEST,
  });
  
  export const fetchFamilyMembersSuccess = (familyMembers) => ({
    type: FETCH_FAMILY_MEMBERS_SUCCESS,
    payload: familyMembers,
  });
  
  export const fetchFamilyMembersFailure = (error) => ({
    type: FETCH_FAMILY_MEMBERS_FAILURE,
    payload: error,
  });
  
  export const addFamilyMember = (familyMember) => ({
    type: ADD_FAMILY_MEMBER_REQUEST,
    payload: familyMember,
  });
  
  export const addFamilyMemberSuccess = (familyMember) => ({
    type: ADD_FAMILY_MEMBER_SUCCESS,
    payload: familyMember,
  });
  
  export const addFamilyMemberFailure = (error) => ({
    type: ADD_FAMILY_MEMBER_FAILURE,
    payload: error,
  });
  
  export const updateFamilyMember = (familyMember) => ({
    type: UPDATE_FAMILY_MEMBER_REQUEST,
    payload: familyMember,
  });
  
  export const updateFamilyMemberSuccess = (familyMember) => ({
    type: UPDATE_FAMILY_MEMBER_SUCCESS,
    payload: familyMember,
  });
  
  export const updateFamilyMemberFailure = (error) => ({
    type: UPDATE_FAMILY_MEMBER_FAILURE,
    payload: error,
  });
  
  export const deleteFamilyMember = (id) => ({
    type: DELETE_FAMILY_MEMBER_REQUEST,
    payload: id,
  });
  
  export const deleteFamilyMemberSuccess = (id) => ({
    type: DELETE_FAMILY_MEMBER_SUCCESS,
    payload: id,
  });
  
  export const deleteFamilyMemberFailure = (error) => ({
    type: DELETE_FAMILY_MEMBER_FAILURE,
    payload: error,
  });

  export const uploadImage = (image) => ({
    type: UPLOAD_IMAGE_REQUEST,
    payload: image,
  });
  
  export const uploadImageSuccess = (imageUrl) => ({
    type: UPLOAD_IMAGE_SUCCESS,
    payload: imageUrl,
  });
  
  export const uploadImageFailure = (error) => ({
    type: UPLOAD_IMAGE_FAILURE,
    payload: error,
  });

  export const selectFamilyMemberRequest = (member) => ({
    type: SELECT_FAMILY_MEMBER_REQUEST,
    payload: member,
  });
  
  export const selectFamilyMemberSuccess = (member) => ({
    type: SELECT_FAMILY_MEMBER_SUCCESS,
    payload: member,
  });
  
  export const selectFamilyMemberFailure = (error) => ({
    type: SELECT_FAMILY_MEMBER_FAILURE,
    payload: error,
  });