import {
  CREATE_BOOKING_REQUEST,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILURE,
  FETCH_USER_BOOKINGS_REQUEST,
  FETCH_USER_BOOKINGS_SUCCESS,
  FETCH_USER_BOOKINGS_FAILURE,
  FETCH_USER_BOOKED_TIMES_REQUEST,
  FETCH_USER_BOOKED_TIMES_SUCCESS,
  FETCH_USER_BOOKED_TIMES_FAILURE,
} from 'constants/Booking';

const initialState = {
  userBookings: [],
  loading: false,
  bookings: [],
  bookedTimes: [],
  error: null,
};

const BookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BOOKING_REQUEST:
    case CANCEL_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: [...state.bookings, action.payload],
      };
    case CREATE_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: state.bookings.filter(booking => booking.id !== action.payload),
      };
    case CANCEL_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_USER_BOOKINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        userBookings: action.payload,
      };
    case FETCH_USER_BOOKINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_USER_BOOKED_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        bookedTimes: action.payload,
      };
    case FETCH_USER_BOOKED_TIMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default BookingReducer;
