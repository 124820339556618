export const SHOW_PASSWORD_RESET_FORM_REQUEST = 'SHOW_PASSWORD_RESET_FORM_REQUEST';
export const SHOW_PASSWORD_RESET_FORM_SUCCESS = 'SHOW_PASSWORD_RESET_FORM_SUCCESS';
export const SHOW_PASSWORD_RESET_FORM_FILURE = 'SHOW_PASSWORD_RESET_FORM_FILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FILURE = 'FORGOT_PASSWORD_FILURE';

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const DR_ACCOUNT_ACTIVATE_REQUEST_EMAIL = "DR_ACCOUNT_ACTIVATE_REQUEST_EMAIL";
export const DR_ACCOUNT_ACTIVATE_SUCCESS_EMAIL = "DR_ACCOUNT_ACTIVATE_SUCCESS_EMAIL";
export const DR_ACCOUNT_ACTIVATE_FAILURE_EMAIL = "DR_ACCOUNT_ACTIVATE_FAILURE_EMAIL";
