import { FETCH_STAT_RESULTS_FAILURE, FETCH_STAT_RESULTS_REQUEST, FETCH_STAT_RESULTS_SUCCESS } from "constants/StatResults";
import { formtValidationErrors } from "utils/Role";

const initState = {
    statLoading: false,
    statResults: [],
    statErrorMessage: ""
};

const StatResults = (state = initState, action) => {
    switch (action.type) {
        case FETCH_STAT_RESULTS_REQUEST:
            return {
                ...state,
                statLoading: true,
            };
        case FETCH_STAT_RESULTS_SUCCESS:
            return {
                ...state,
                statLoading: false,
                statResults: action.payload,
            };
        case FETCH_STAT_RESULTS_FAILURE:
            return {
                ...state,
                statLoading: false,
                statErrorMessage: action.payload,
            };

        default:
            return state;
    }
};

export default StatResults;
