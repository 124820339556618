
import {
    FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
    FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
    FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS,

    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST,
    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE,
    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS
} from "constants/DailyFinancialSummary";


const initState = {
     loading : false, 
     list : [],
     summeryreports : [],
     errorMessage : {},
}

const  DailyFinancialSummary = (state = initState, action) =>{
     switch(action.type){
        case FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST :
            return {
                ...state,
                loading : true,
                
                //added
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            }

        case FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS :
            return {
                ...state,
                list : action.payload,
                loading : false
            }

        case FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE :
            return {
                ...state,
                loading : false
            }
        case CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST:
            return {
                ...state,
                loading: true
                
            } 
        case CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS:
            return {
                ...state,
                summeryreports : action.payload,
                loading: false,
                updating: false
            }
        case CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                updating: false
            }
        default : return state;       
     }
}

export default DailyFinancialSummary;