import fetch from "auth/FetchInterceptor";

const FamilyService = {};

FamilyService.getAll = function () {
  return fetch({
    url: "/family-members",
    method: "get",
  });
};

FamilyService.get = function (id) {
  return fetch({
    url: `/family-members/${id}`,
    method: "get",
  });
};

FamilyService.create = function (payload) {
  return fetch({
    url: "/family-members",
    method: "post",
    data: payload,
  });
};

FamilyService.update = function (id, payload) {
  return fetch({
    url: `/family-members/${id}`,
    method: "put",
    data: payload,
  });
};

FamilyService.delete = function (id) {
  return fetch({
    url: `/family-members/${id}`,
    method: "delete",
  });
};

FamilyService.uploadImage = function (payload) {
  return fetch({
    url: "/upload-image",
    method: "post",
    data: payload,
  }).then(response => {
    return response;
  }).catch(error => {
    throw error;
  });
};

export default FamilyService;