import {
    FETCH_FAMILY_MEMBERS_SUCCESS,
    ADD_FAMILY_MEMBER_SUCCESS,
    UPDATE_FAMILY_MEMBER_SUCCESS,
    DELETE_FAMILY_MEMBER_SUCCESS,
    FETCH_FAMILY_MEMBERS_REQUEST,
    FETCH_FAMILY_MEMBERS_FAILURE,
    DELETE_FAMILY_MEMBER_REQUEST,
    DELETE_FAMILY_MEMBER_FAILURE,
    UPDATE_FAMILY_MEMBER_REQUEST,
    UPDATE_FAMILY_MEMBER_FAILURE,
    SELECT_FAMILY_MEMBER_REQUEST,
    SELECT_FAMILY_MEMBER_SUCCESS,
    SELECT_FAMILY_MEMBER_FAILURE,
    UPLOAD_IMAGE_REQUEST,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,
  } from 'constants/FamilyMember';

const initialState = {
    familyMembers: [],
    isLoading: false,
    error: null,
    uploadedImageUrl: '',
    selectedFamilyMember: null,
  };
  
  const familyReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_FAMILY_MEMBERS_REQUEST:
        return { 
          ...state, 
          isLoading:true
        };

        case FETCH_FAMILY_MEMBERS_SUCCESS:
          return { 
            ...state, 
            isLoading:false,
            familyMembers:action.payload
          };

        case FETCH_FAMILY_MEMBERS_FAILURE:
          return { 
            ...state, 
            isLoading:false,
            error: action.payload
          };

        case DELETE_FAMILY_MEMBER_REQUEST:
          return { 
            ...state, 
            isLoading:true
          };
  
        case DELETE_FAMILY_MEMBER_SUCCESS:
          return {
            ...state,
            familyMembers: state.familyMembers.filter((member) => member.id !== action.payload),
            isLoading: false,
          };

        case DELETE_FAMILY_MEMBER_FAILURE:
          return { 
            ...state, 
            isLoading:false,
            error: action.payload
          };

        case UPDATE_FAMILY_MEMBER_REQUEST:
          return { 
            ...state, 
            isLoading:true
          };
  
          case UPDATE_FAMILY_MEMBER_SUCCESS:
            return {
              ...state,
                familyMembers: state.familyMembers.map((member) =>
                member.id === action.payload.id ? action.payload : member
              ),
              isLoading: false,
            };
  
          case UPDATE_FAMILY_MEMBER_FAILURE:
            return { 
              ...state, 
              isLoading:false,
              error: action.payload
            };

          case UPLOAD_IMAGE_REQUEST:
            return {
              ...state,
              isLoading: true,
              error: null,
            };
          case UPLOAD_IMAGE_SUCCESS:
            return {
              ...state,
              isLoading: false,
              uploadedImageUrl: action.payload,
            };
          case UPLOAD_IMAGE_FAILURE:
            return {
              ...state,
              isLoading: false,
              error: action.payload,
            };
          case SELECT_FAMILY_MEMBER_REQUEST:
            return {
              ...state,
              isSelectingFamilyMember: true,
              selectFamilyMemberError: null,
            };
      
          case SELECT_FAMILY_MEMBER_SUCCESS:
            return {
              ...state,
              isSelectingFamilyMember: false,
              selectedFamilyMember: action.payload,
            };
      
          case SELECT_FAMILY_MEMBER_FAILURE:
            return {
              ...state,
              isSelectingFamilyMember: false,
              selectFamilyMemberError: action.payload,
            };
      default:
        return state;
    }
  };
  
  export default familyReducer;