import fetch from "auth/FetchInterceptor";

const OrderedOrders = {};

OrderedOrders.getOrderedOrders = function (payload) {
    return fetch({
        url: `/reports/ordered-orders?startdate=
            ${payload.startDate}&enddate=
            ${payload.endDate}&agency=
            ${payload.agency}&department=
            ${payload.department}`,
        method: "get",
    });
};

export default OrderedOrders;
