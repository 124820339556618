import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message, Alert } from "antd";
import { ROLE_GUEST, UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import { env } from "configs/EnvironmentConfig";
import {
  FETCH_LOCATION_LOOKUP_REQUEST,
  FETCH_ZONE_REQUEST,
  FETCH_WOREDA_REQUEST,
  FETCH_NEW_ORDER_TESTS_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
  UPDATE_NEW_ORDER_REQUEST,
  CREATE_NEW_ORDER_REQUEST,
  CREATE_NEW_ORDER_BY_DOCTOR_REQUEST,
  UPDATE_CS_OR_FS_NUMBER_REQUEST
} from "constants/NewOrder";
import {
  fetchNewOrderTestSuccess,
  fetchNewOrderTestFailure,
  fetchLocationLookUpSuccess,
  fetchLocationLookUpFailure,
  setNewOrderValidationErrors,
  fetchNewOrderPatientsSuccess,
  fetchNewOrderPatientsFailure,
  updateNewOrderSuccess,
  updateNewOrderFailure,
  createNewOrderSuccess,
  createNewOrderFailure,
  fetchzoneSuccess,
  fetchzoneFailure,
  fetchworedaSuccess,
  fetchworedaFailure,
  updateCsOrFsNumberSuccess,
  updateCsOrFsNumberFailure,
} from "redux/actions";

import NewOrderService from "services/NewOrder";
import { doesThisUserIsSrd, getCurrentRole } from "utils/CurrentUserUtil";
import { sendSampleToSampleManagement } from "utils/SampleManagement";
import { openPdf } from "views/admin-views/neworder/openPatientOrderSummary";
// import { sendSampleToSampleManagement } from "utils/SampleManagement";

export function* onFetchNewOrderTest() {
  yield takeLatest(FETCH_NEW_ORDER_TESTS_REQUEST, function* () {
    try {
      const result = yield call(NewOrderService.index);
      if (result.status) {
        yield put(fetchNewOrderTestSuccess(result.data));
      } else {
        yield put(fetchNewOrderTestFailure(result.message));
      }
    } catch (error) {
      yield put(fetchNewOrderTestFailure(error.message));
    }
  });
}

export function* onFetchLocationLookUp() {
  yield takeLatest(FETCH_LOCATION_LOOKUP_REQUEST, function* () {
    try {
      const result = yield call(NewOrderService.getLocations);
      if (result.status) {
        yield put(fetchLocationLookUpSuccess(result.data));
      } else {
        yield put(fetchLocationLookUpFailure(result.message));
      }
    } catch (error) {
      yield put(fetchLocationLookUpFailure(error.message));
    }
  });
}

export function* onFetchzone() {
  yield takeLatest(FETCH_ZONE_REQUEST, function* (parentid) {
    try {
      const result = yield call(NewOrderService.getzone, parentid);
      if (result.status) {
        yield put(fetchzoneSuccess(result.data));
      } else {
        yield put(fetchzoneFailure(result.message));
      }
    } catch (error) {
      yield put(fetchzoneFailure(error.message));
    }
  });
}

export function* onFetchworeda() {
  yield takeLatest(FETCH_WOREDA_REQUEST, function* (parentid) {
    try {
      const result = yield call(NewOrderService.getzone, parentid);
      if (result.status) {
        yield put(fetchworedaSuccess(result.data));
      } else {
        yield put(fetchworedaFailure(result.message));
      }
    } catch (error) {
      yield put(fetchworedaFailure(error.message));
    }
  });
}

export function* onFetchExistingPatients() {
  yield takeLatest(FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(NewOrderService.searchpatient, payload);
      if (result.status === false) {
        // message.error("Something went wrong");
        yield put(fetchNewOrderPatientsFailure(result.message));
      } else if( result.status === true && result.data.length === 0){
        // message.warning("Not found");
        yield put(fetchNewOrderPatientsFailure("Not found"));
      } else {
        yield put(fetchNewOrderPatientsSuccess(result.data));
      }
    } catch (error) {
      yield put(fetchNewOrderPatientsFailure(error.message));
    }
  });
}

export function* onCreateNewOrder() {
  yield takeLatest(CREATE_NEW_ORDER_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(NewOrderService.create, payload.newOrder);
      if (result.status && doesThisUserIsSrd()) {
        yield put(createNewOrderSuccess(result.data));
        let orderInformation = result.data;
        let sampleId = orderInformation.visitInfo.webSampleId;
        openPdf(sampleId, true, "trf");
        //and open barcode at the same time
        let url = env.PDF_API_BASE_URL + "/barcode/pdf?sampleid=" +  sampleId + "&amount=5";
        let win = window.open(url, '_blank');
        win.focus();
      }else if (result.status && getCurrentRole() !== ROLE_GUEST) {
        let orderInformation = result.data;
        let sampleId = orderInformation.visitInfo.webSampleId;
        // payload.sampleManagementData.sample_ID = sampleId;
        // sendSampleToSampleManagement(payload.sampleManagementData);
        yield put(createNewOrderSuccess(result.data));
        openPdf(sampleId, payload.doesThePdfAllowedToBeOpened, payload.pdfType);
      } else if (result.status && getCurrentRole() === ROLE_GUEST) {
        yield put(createNewOrderSuccess(result.data));
        if (result.data.ledgerInfo[0].booking_id != null) {
          message.success("Booking Created successfully!")
        }
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setNewOrderValidationErrors(result.data.errors));
        yield put(createNewOrderFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createNewOrderFailure(result.message));
      }

    } catch (error) {
      message.error("Something went wrong");
      yield put(createNewOrderFailure(error.message));
    }
  });
}


export function* onCreateNewOrderByDoctor() {
  yield takeLatest(CREATE_NEW_ORDER_BY_DOCTOR_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(NewOrderService.create, payload.newOrder);
      if (result.status && getCurrentRole() !== ROLE_GUEST) {
        let orderInformation = result.data;
        let sampleId = orderInformation.visitInfo.webSampleId;
        payload.sampleManagementData.sample_ID = sampleId;
        sendSampleToSampleManagement(payload.sampleManagementData);
        yield put(createNewOrderSuccess(result.data));
        message.success("Order sent successfully!")
        // openPdf(sampleId, payload.doesThePdfAllowedToBeOpened, "forCash" );
      } else if (result.status && getCurrentRole() === ROLE_GUEST) {
        yield put(createNewOrderSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setNewOrderValidationErrors(result.data.errors));
        yield put(createNewOrderFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createNewOrderFailure(result.message));
      }

    } catch (error) {
      message.error("Something went wrong");
      yield put(createNewOrderFailure(error.message));
    }
  });
}


export function* onUpdateCsOrFsNumber() {
  yield takeLatest(UPDATE_CS_OR_FS_NUMBER_REQUEST, function* ({ payload }) {
    try {
      // console.log('--->saga payload',payload);
      const result = yield call(
        NewOrderService.updateCacheRegister,
        payload.payload
      );
      if (result.status) {
        yield put(updateCsOrFsNumberSuccess(result.data));
        openPdf(payload.payload.generatedSampleId, true, payload.pdfType);
      } else  {
        message.error("Error updating cash register machine");
        yield put(updateCsOrFsNumberFailure(result.message));  
      } 
    } catch (error) {
      message.error("Error updating cash register machine");
      yield put(updateCsOrFsNumberFailure(error.message));
    }
  });
}


export function* onUpdateNewOrder() {
  yield takeLatest(UPDATE_NEW_ORDER_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        NewOrderService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateNewOrderSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setNewOrderValidationErrors(result.data.errors));
        yield put(updateNewOrderFailure(result.message));
      } else {
        yield put(updateNewOrderFailure(result.message));
      }
    } catch (error) {
      yield put(updateNewOrderFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchzone),
    fork(onFetchworeda),
    fork(onFetchNewOrderTest),
    fork(onFetchLocationLookUp),
    fork(onFetchExistingPatients),
    fork(onCreateNewOrder),
    fork(onUpdateCsOrFsNumber),
    fork(onUpdateNewOrder),
    fork(onCreateNewOrderByDoctor)
  ]);
}
