export const Greeting = ({ currentUser }) => {
    const hour = new Date().getHours();
    const userName = currentUser?.name || "Guest";
    if (hour < 12) {
        return `🌅 Good morning ${userName} !`;
    } else if (hour < 18) {
        return `🌤️ Good afternoon ${userName} !`;
    } else {
        return `🌙 Good evening ${userName} !`;
    }
};
