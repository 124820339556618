import Loading from "components/shared-components/Loading";
import { ASCLEPIUS_PREFIX_PATH } from "configs/AppConfig";
import { VIEW_ASCLEPIUS_DASHBOARD } from "configs/UserPermissions";
import { lazy, Suspense } from "react";
import { Route, Switch, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CURRENT_USER } from "redux/constants/Auth";
import { DoesThisUserHasAPermissionToThisComponent, doesThisUserIsAsclepiusUser } from "utils/CurrentUserUtil";
import PageNotFoundPage from "views/PageNotFound";
import UnAuthorizedPage from "views/UnAuthorized";
 
const AsclepiusAppViews = () => {
  // const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  // const role = currentUser?.role;
  // const history = useHistory();
  
  return (
    <div>
      <Suspense fallback={<Loading cover="content" />}>
      {
         doesThisUserIsAsclepiusUser() ? 
         <Switch>
            <Route 
            exact
            path={`${ASCLEPIUS_PREFIX_PATH}`}
            component={lazy(() => import(`./dashboard/list/index`))}
          />
          <Route 
            exact
            path={`${ASCLEPIUS_PREFIX_PATH}/ElectronicOrders`}
            component={lazy(() => import(`./dashboard/list/index`))}
          />
          <Route
            exact
            path={`${ASCLEPIUS_PREFIX_PATH}/ProcessOrders`}
            component={lazy(() => import(`./ProcessOrders/list/index`))}
          />

          <Route
            exact
            path={`${ASCLEPIUS_PREFIX_PATH}/Report`}
            component={lazy(() => import(`./Report/list/index`))}
          />

            <Route
            path="*">
                <PageNotFoundPage/>
            </Route>

          </Switch>
          : <UnAuthorizedPage/>

      }
      </Suspense>
    </div>
  );
};

export default AsclepiusAppViews;
