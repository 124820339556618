import {
  DELETE_CLIENT_COMPANY_FAILURE,
  DELETE_CLIENT_COMPANY_REQUEST,
  DELETE_CLIENT_COMPANY_SUCCESS,
  FETCH_CLIENT_COMPANIES_FAILURE,
  FETCH_CLIENT_COMPANIES_REQUEST,
  FETCH_CLIENT_COMPANIES_SUCCESS,
  CREATE_CLIENT_COMPANY_FAILURE,
  CREATE_CLIENT_COMPANY_REQUEST,
  CREATE_CLIENT_COMPANY_SUCCESS,
  SHOW_CLIENT_COMPANY_EDIT_FORM,
  SHOW_CLIENT_COMPANY_NEW_FORM,
  UPDATE_CLIENT_COMPANY_FAILURE,
  UPDATE_CLIENT_COMPANY_REQUEST,
  UPDATE_CLIENT_COMPANY_SUCCESS,
  SET_CLIENT_COMPANY_VALIDATION_ERRORS,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS
} from "constants/ClientCompany";

export const fetchClientCompaniesRequest = () => ({
  type: FETCH_CLIENT_COMPANIES_REQUEST,
});

export const fetchClientCompaniesSuccess = (clientCompanies) => ({
  type: FETCH_CLIENT_COMPANIES_SUCCESS,
  payload: clientCompanies,
});

export const fetchClientCompaniesFailure = (error) => ({
  type: FETCH_CLIENT_COMPANIES_FAILURE,
  payload: error,
});

export const setClientCompanyValidationErrors = (validationErrors) => ({
  type: SET_CLIENT_COMPANY_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleClientCompanyEditForm = () => ({
  type: SHOW_CLIENT_COMPANY_EDIT_FORM,
});

export const toggleClientCompanyNewForm = () => ({
  type: SHOW_CLIENT_COMPANY_NEW_FORM,
});

export const updateClientCompanyRequest = (clientCompany) => ({
  type: UPDATE_CLIENT_COMPANY_REQUEST,
  payload: clientCompany,
});

export const updateClientCompanySuccess = (clientCompany) => ({
  type: UPDATE_CLIENT_COMPANY_SUCCESS,
  payload: clientCompany,
});

export const updateClientCompanyFailure = (error) => ({
  type: UPDATE_CLIENT_COMPANY_FAILURE,
  payload: error,
});

export const deleteClientCompanyRequest = (id) => ({
  type: DELETE_CLIENT_COMPANY_REQUEST,
  payload: id,
});

export const deleteClientCompanySuccess = (clientCompany) => ({
  type: DELETE_CLIENT_COMPANY_SUCCESS,
  payload: clientCompany,
});

export const deleteClientCompanyFailure = (error) => ({
  type: DELETE_CLIENT_COMPANY_FAILURE,
  payload: error,
});

export const createClientCompanyRequest = (clientCompany) => ({
  type: CREATE_CLIENT_COMPANY_REQUEST,
  payload: clientCompany,
});

export const createClientCompanySuccess = (clientCompany) => ({
  type: CREATE_CLIENT_COMPANY_SUCCESS,
  payload: clientCompany,
});

export const createClientCompanyFailure = (error) => ({
  type: CREATE_CLIENT_COMPANY_FAILURE,
  payload: error,
});


export const fetchDashboardRequest = (startdate, enddate, agency) => ({
  type: FETCH_DASHBOARD_REQUEST,
  payload: {
    startdate,
    enddate,
    agency
  }
});

export const fetchDashboardSuccess = (dashboard) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: dashboard,
});

export const fetchDashboardFailure = (error) => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: error,
});