import {
    FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST,
    FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS,
    FETCH_ADMIN_DASHBOARD_REPORTS_FAILURE,
    FETCH_CAHSHIER_DASHBOARD_REPORTS_REQUEST,
    FETCH_CAHSHIER_DASHBOARD_REPORTS_SUCCESS,
    FETCH_CAHSHIER_DASHBOARD_REPORTS_FAILURE,
} from "constants/AdminDashboardReports/DashboardReport"

const initState = {
    dashboardReport: [],
    cashierDashboard: [],
    cashierLoading: true,
    loading: true,
    error: ""
}

const DashboardReports = (state = initState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                dashboardReport: action.payload
            }
        case FETCH_ADMIN_DASHBOARD_REPORTS_FAILURE:
            return {
                ...state,
                loading: false,
                dashboardReport: [],
                error: action.payload
            }

        case FETCH_CAHSHIER_DASHBOARD_REPORTS_REQUEST:
            return {
                ...state,
                cashierLoading: true
            }
        case FETCH_CAHSHIER_DASHBOARD_REPORTS_SUCCESS:
            return {
                ...state,
                cashierLoading: false,
                cashierDashboard: action.payload
            }
        case FETCH_CAHSHIER_DASHBOARD_REPORTS_FAILURE:
            return {
                ...state,
                cashierLoading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default DashboardReports;