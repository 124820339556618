import {
    FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
    FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
    FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS,

    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST,
    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE,
    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS

} from "constants/DailyFinancialSummary";


export const fetchDailyFinancialSummaryRequest = (startDate, endDate, agency, cashiercode, cashorcredit) => {
    const payload = {
      startDate, 
      endDate, 
      agency,
      cashiercode,
      cashorcredit,
    };
    return {
      type: FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
      payload: payload
    };
  };

export const fetchDailyFinancialSummarySuccess = (payload) =>({
    type : FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS,
    payload : payload
});

export const fetchDailyFinancialSummaryFailure = (payload) => ({
    type : FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
    payload : payload
});

export const createDailyTransactionSummeryReportRequest = (startDate, endDate, agency, cashiercode, cashorcredit) => {  
  const payload = {
    startDate, 
    endDate, 
    agency,
    cashiercode,
    cashorcredit,
  };
  return {
    type: CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST,
    payload: payload
  };
}

export const createDailyTransactionSummeryReportSuccess = (payload) => ({
    type : CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS,
    payload : payload
});

export const createDailyTransactionSummeryReportFailure = (payload) => ({
    type : CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE,
    payload : payload
});
