import { SEARCH_DOCTORS_FAILURE, SEARCH_DOCTORS_REQUEST, SEARCH_DOCTORS_SUCCESS } from "constants/SearchDoctors";
  
export const searchDoctorsRequest = (query) => ({
    type: SEARCH_DOCTORS_REQUEST,
    payload: query
});

export const searchDoctorsSuccess = (payload) => (
    {
    type: SEARCH_DOCTORS_SUCCESS,
    payload: payload,
});

export const searchDoctorsFailure = (error) => ({
    type: SEARCH_DOCTORS_FAILURE,
    payload: error
});