import {
  CREATE_BOOKING_REQUEST,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAILURE,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILURE,
  FETCH_USER_BOOKINGS_REQUEST,
  FETCH_USER_BOOKINGS_SUCCESS,
  FETCH_USER_BOOKINGS_FAILURE,
  FETCH_USER_BOOKED_TIMES_REQUEST,
  FETCH_USER_BOOKED_TIMES_SUCCESS,
  FETCH_USER_BOOKED_TIMES_FAILURE,
} from 'constants/Booking';

export const createBookingRequest = (booking, callback) => ({
  type: CREATE_BOOKING_REQUEST,
  payload: booking,
  callback,
});

export const createBookingSuccess = (booking) => ({
  type: CREATE_BOOKING_SUCCESS,
  payload: booking,
});

export const createBookingFailure = (error) => ({
  type: CREATE_BOOKING_FAILURE,
  payload: error,
});

export const cancelBookingRequest = (bookingId, callback) => ({
  type: CANCEL_BOOKING_REQUEST,
  payload: { bookingId },
  callback,
});

export const cancelBookingSuccess = (bookingId) => ({
  type: CANCEL_BOOKING_SUCCESS,
  payload: bookingId,
});

export const cancelBookingFailure = (error) => ({
  type: CANCEL_BOOKING_FAILURE,
  payload: error,
});

export const fetchUserBookingsRequest = (date, branch_id) => ({
  type: FETCH_USER_BOOKINGS_REQUEST,
  payload: { date, branch_id },
});

export const fetchUserBookingsSuccess = (bookings) => ({
  type: FETCH_USER_BOOKINGS_SUCCESS,
  payload: bookings,
});

export const fetchUserBookingsFailure = (error) => ({
  type: FETCH_USER_BOOKINGS_FAILURE,
  payload: error,
});

export const fetchUserBookedTimesRequest = () => ({
  type: FETCH_USER_BOOKED_TIMES_REQUEST,
});

export const fetchUserBookedTimesSuccess = (bookedTimes) => ({
  type: FETCH_USER_BOOKED_TIMES_SUCCESS,
  payload: bookedTimes,
});

export const fetchUserBookedTimesFailure = (error) => ({
  type: FETCH_USER_BOOKED_TIMES_FAILURE,
  payload: error,
});
