import React from 'react';
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Steps, Typography, Card, Progress } from 'antd';

const { Title, Paragraph } = Typography;

const WaitingForConfirmation = ({isMobile}) => {
  const currentStep = 2;

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '20px',
      }}
    >
      <Card
        style={{
          textAlign: 'center',
          padding: '50px',
          maxWidth: '600px',
          width: '100%',
          borderRadius: '15px',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
          // backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Title level={2} style={{ marginBottom: '20px'}}>
          Verifying Your Information
        </Title>
        <Steps direction= { isMobile ? 'vertical' : 'horizontal'} current={currentStep} style={{ marginBottom: '30px' }}>
          <Steps.Step title="Login" icon={<UserOutlined />} />
          <Steps.Step title="Verification" icon={<SolutionOutlined />} />
          <Steps.Step title="Approval" icon={<LoadingOutlined />} />
          <Steps.Step title="Done" icon={<SmileOutlined />} />
        </Steps>
        <Progress
          percent={(currentStep / 3) * 100}
          status="active"
          showInfo={false}
          strokeColor="#1890ff"
          style={{ marginBottom: '20px' }}
        />
        <Paragraph>
          Your account is under review. Please wait while we complete the approval process.
        </Paragraph>
      </Card>
    </div>
  );
};

export default WaitingForConfirmation;
