import { call, put, takeLatest, all } from 'redux-saga/effects';
import TimeSlotService from 'services/TimeSlotService';
import { FETCH_TIME_SLOTS_REQUEST } from 'constants/TimeSlot';
import {
  fetchTimeSlotsSuccess,
  fetchTimeSlotsFailure,
} from '../actions/TimeSlot';

function* fetchTimeSlotsSaga(action) {
  try {
    const { date, branch_id, timezone } = action.payload; // **Destructure payload**
    const response = yield call(TimeSlotService.getTimeSlots, { date, branch_id, timezone });
    if (response.status) {
      yield put(fetchTimeSlotsSuccess(response.data));
    } else {
      yield put(fetchTimeSlotsFailure(response.message));
    }
  } catch (error) {
    yield put(fetchTimeSlotsFailure(error.message));
  }
}

function* watchTimeSlotSaga() {
  yield takeLatest(FETCH_TIME_SLOTS_REQUEST, fetchTimeSlotsSaga);
}

export default function* rootSaga() {
  yield all([
    watchTimeSlotSaga(),
  ]);
}