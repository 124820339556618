import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { connect } from "react-redux";
import { DoesThisUserHasAPermissionToThisComponent } from "utils/CurrentUserUtil";
import {
  VIEW_ALL_INCOMPLETE_RESULTS,
  VIEW_ALL_ORDERS,
  VIEW_ALL_RELEASED_RESULTS,
  VIEW_COMPLETED_RESULTS,
  VIEW_DELCINED_ORDERS,
  VIEW_NEWORDERS,
  VIEW_PANIC_AND_ABNORMAL_TESTS,
  VIEW_PENDING_ORDERS,
  VIEW_SAMPLE_IN_PROCESS,
} from "configs/UserPermissions";
import {
  fetchAppointmentMetadataRequest,
  fetchBranchLookUpsRequest,
} from "redux/actions";

export const AppViews = ({
  fetchAppointmentMetadataRequest,
  fetchBranchLooUp,
  loadingAppointmentMetadata,
  loadingBranchLookUp,
}) => {
  useEffect(() => {
    fetchAppointmentMetadataRequest();
    fetchBranchLooUp();
  }, [fetchAppointmentMetadataRequest, fetchBranchLooUp]);
  return loadingAppointmentMetadata || loadingBranchLookUp ? (
    <Loading cover="content" />
  ) : (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/neworder`}
          component={lazy(() => import(`../admin-views/neworder/neworder/index.js`))}
        />
        {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) && (
          <Route
          exact
          path={`${APP_PREFIX_PATH}/all-orders`}
          component={lazy(() => import(`../admin-views/orders/all-orders/list`))}
        />
        )}
        {DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_ORDERS) && (
        <Route
          exact
          path={`${APP_PREFIX_PATH}/pending-orders`}
          component={lazy(() => import(`../admin-views/orders/pending-orders/list`))}
        />
        )}
        {DoesThisUserHasAPermissionToThisComponent(VIEW_SAMPLE_IN_PROCESS) && (
        <Route
          exact
          path={`${APP_PREFIX_PATH}/sample-in-process`}
          component={lazy(() => import(`../admin-views/sample-in-process/list`))}
        />
        )}
        {DoesThisUserHasAPermissionToThisComponent(VIEW_DELCINED_ORDERS) && (
        <Route
          exact
          path={`${APP_PREFIX_PATH}/declined-orders`}
          component={lazy(() => import(`../admin-views/orders/declined-orders/list`))}
        />
        )}
        {DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) && (
        <Route
          exact
          path={`${APP_PREFIX_PATH}/show-patient-timeline`}
          component={lazy(() => import(`../admin-views/time-line`))}
        />
        )}
        {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_RELEASED_RESULTS) && (
        <Route
          exact
          path={`${APP_PREFIX_PATH}/patient-result`}
          component={lazy(() =>
            import(`../admin-views/released-results/patient-result/list`)
          )}
        />
        )}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/bookings`}
          component={lazy(() =>
            import(`./booking/bookings`)
          )}
        />
         <Route
          exact
          path={`${APP_PREFIX_PATH}/ordermadebyguestusers`}
          component={lazy(() =>
            import(`../admin-views/released-results/ordermadebyguestusers/list`)
          )}
        />



        {/* {DoesThisUserHasAPermissionToThisComponent(VIEW_COMPLETED_RESULTS) && (
        <Route
          exact
          path={`${APP_PREFIX_PATH}/completed-results`}
          component={lazy(() =>
            import(`../admin-views/released-results/completed-results/list`)
          )}
        />
        )}
        {DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_INCOMPLETE_RESULTS) && (
        <Route
          exact
          path={`${APP_PREFIX_PATH}/incomplete-results`}
          component={lazy(() =>
            import(`../admin-views/released-results/incomplete-results/list`)
          )}
        />
        )}
        {DoesThisUserHasAPermissionToThisComponent(VIEW_PANIC_AND_ABNORMAL_TESTS) && (
        <Route
          exact
          path={`${APP_PREFIX_PATH}/panicandabnormaltests`}
          component={lazy(() =>
            import(`../admin-views/released-results/panicandabnormaltests/list`)
          )}
        />
        )} */}
        <Route
          exact
          path={`${APP_PREFIX_PATH}/profile/update`}
          component={lazy(() => import(`../customer-app-views/customer-profile-update/EditCustomerProfile`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};
const mapDispatchToProps = (dispatch) => ({
  fetchAppointmentMetadataRequest: () =>
    dispatch(fetchAppointmentMetadataRequest()),
  fetchBranchLooUp: () => dispatch(fetchBranchLookUpsRequest()),
});

const mapStateToProps = (state) => ({
  loadingAppointmentMetadata: state.appointment.loadingAppointmentMetadata,
  loadingBranchLookUp: state.branchLookUp.loadingBranchLookUp,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AppViews));
