import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import FamilyService from 'services/FamilyMember';
import {
  FETCH_FAMILY_MEMBERS_REQUEST,
  ADD_FAMILY_MEMBER_REQUEST,
  UPDATE_FAMILY_MEMBER_REQUEST,
  DELETE_FAMILY_MEMBER_REQUEST,
  SELECT_FAMILY_MEMBER_REQUEST,
  UPLOAD_IMAGE_REQUEST,
} from 'constants/FamilyMember';
import {
  fetchFamilyMembersSuccess,
  fetchFamilyMembersFailure,
  addFamilyMemberSuccess,
  addFamilyMemberFailure,
  updateFamilyMemberSuccess,
  updateFamilyMemberFailure,
  deleteFamilyMemberSuccess,
  deleteFamilyMemberFailure,
  selectFamilyMemberSuccess,
  selectFamilyMemberFailure,
  uploadImageSuccess,
  uploadImageFailure,
} from '../actions/FamilyMember';

function* fetchFamilyMembersSaga() {
  try {
    const response = yield call(FamilyService.getAll);
    if (response.status) {
      yield put(fetchFamilyMembersSuccess(response.data));
    } else {
      yield put(fetchFamilyMembersFailure(response.message));
    }
  } catch (error) {
    yield put(fetchFamilyMembersFailure(error.message));
  }
}

function* addFamilyMemberSaga(action) {
  try {
    const response = yield call(FamilyService.create, action.payload);
    yield put(addFamilyMemberSuccess(response.data));
  } catch (error) {
    yield put(addFamilyMemberFailure(error));
  }
}

function* updateFamilyMemberSaga(action) {
  try {
    const { id, familyMember } = action.payload;
    const response = yield call(FamilyService.update, id, familyMember);
    if (response.status) {
      yield put(updateFamilyMemberSuccess(response.data));
    } else {
      yield put(updateFamilyMemberFailure(response.message));
    }
  } catch (error) {
    yield put(updateFamilyMemberFailure(error.message));
  }
}

function* deleteFamilyMemberSaga(action) {
  try {
    yield call(FamilyService.delete, action.payload);
    yield put(deleteFamilyMemberSuccess(action.payload));
  } catch (error) {
    yield put(deleteFamilyMemberFailure(error));
  }
}

function* uploadImageSaga(action) {
  try {
    const response = yield call(FamilyService.uploadImage, action.payload);
    if (response.status) {
      yield put(uploadImageSuccess(response.data.image_url));
    } else {
      const errorMessage = response.message || 'Image upload failed';
      yield put(uploadImageFailure(errorMessage));
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Image upload failed';
    yield put(uploadImageFailure(errorMessage));
  }
}

function* selectFamilyMemberSaga(action) {
  try {
    const member = action.payload;
    yield put(selectFamilyMemberSuccess(member));
  } catch (error) {
    yield put(selectFamilyMemberFailure(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    fork(function* () {
      yield takeLatest(FETCH_FAMILY_MEMBERS_REQUEST, fetchFamilyMembersSaga);
    }),
    fork(function* () {
      yield takeLatest(ADD_FAMILY_MEMBER_REQUEST, addFamilyMemberSaga);
    }),
    fork(function* () {
      yield takeLatest(UPDATE_FAMILY_MEMBER_REQUEST, updateFamilyMemberSaga);
    }),
    fork(function* () {
      yield takeLatest(DELETE_FAMILY_MEMBER_REQUEST, deleteFamilyMemberSaga);
    }),
    fork(function* () {
      yield takeLatest(UPLOAD_IMAGE_REQUEST, uploadImageSaga);
    }),
    fork(function* () {
      yield takeLatest(SELECT_FAMILY_MEMBER_REQUEST, selectFamilyMemberSaga);
    }),
  ]);
}