import fetch from "auth/FetchInterceptor";

const PendingOrders = {};

PendingOrders.get = function (payload) {
  return fetch({
    url: `/reports/orders-pending?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}&department=${payload.department}`,
    method: "get",
  });
};

PendingOrders.getObx = function (payload = null) {
  return fetch({
    url: "/reports/getOrderObx?visitUuid=" + payload,
    method: "get",
  })
}


PendingOrders.update = function (data) {
  return fetch({
    url: "/update-order-status",
    method: "post",
    data,
  });
};


export default PendingOrders;
