import {
    FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST,
    FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS,
    FETCH_ADMIN_DASHBOARD_REPORTS_FAILURE,
    FETCH_CAHSHIER_DASHBOARD_REPORTS_FAILURE,
    FETCH_CAHSHIER_DASHBOARD_REPORTS_SUCCESS,
    FETCH_CAHSHIER_DASHBOARD_REPORTS_REQUEST,
} from "constants/AdminDashboardReports/DashboardReport"

export const fetchAdminReportsRequest = (startDate, endDate, agency, dateDifference) => {
    return {
        type: FETCH_ADMIN_DASHBOARD_REPORTS_REQUEST,
        payload: {
            startDate,
            endDate,
            agency,
            dateDifference
        }
    }
}

export const fetchAdminReportsSuccess = (payload) => {
    return {
        type: FETCH_ADMIN_DASHBOARD_REPORTS_SUCCESS,
        payload: payload
    }
}

export const fetchAdminReportsFailure = (errorMessage) => {
    return {
        type: FETCH_ADMIN_DASHBOARD_REPORTS_FAILURE,
        payload: errorMessage
    }
}

export const fetchCashierDashboardRequest = (startdate, enddate, agency, dateDifference) => {
    return {
        type: FETCH_CAHSHIER_DASHBOARD_REPORTS_REQUEST,
        payload: {
            startdate,
            enddate,
            agency,
            dateDifference
        }
    }
}

export const fetchCashierDashboardSuccess = (payload) => {
    return {
        type: FETCH_CAHSHIER_DASHBOARD_REPORTS_SUCCESS,
        payload: payload
    }
}

export const fetchCashierDashboardFailure = (error) => {
    return {
        type: FETCH_CAHSHIER_DASHBOARD_REPORTS_FAILURE,
        payload: error
    }
}