import {
  FETCH_PATIENTTESTRESULTHISTORY_FAILURE,
  FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  FETCH_PATIENTTESTRESULTHISTORY_SUCCESS,
  SHOW_NEW_ORDER_SEARCH_FORM,
  FETCH_EXISTING_PATIENT_REQUEST,
  FETCH_EXISTING_PATIENT_FAILURE,
  FETCH_EXISTING_PATIENT_SUCCESS,
  UPDATE_GRAPH_DATA
} from "constants/PatientTestResultHistory";

export const fetchPatientTestResultHistoryRequest = (patientid, sampleid, patientFullName) => ({
  type: FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  payload: {
    patientid, sampleid, patientFullName
  }
});

export const fetchPatientTestResultHistorySuccess = (patienttestresulthistory) => ({
  type: FETCH_PATIENTTESTRESULTHISTORY_SUCCESS,
  payload: patienttestresulthistory,
});



export const fetchPatientTestResultHistoryFailure = (error) => ({
  type: FETCH_PATIENTTESTRESULTHISTORY_FAILURE,
  payload: error,
});



export const toggleNewOrderPatientSearchForm = () => ({
  type: SHOW_NEW_ORDER_SEARCH_FORM,
});

export const fetchPatientRequest = (startDate,
  endDate,searchKey ,mrnnumber,phone, fullname, patientid, sampleid,searchtype="without_order_and_result") => ({
  type: FETCH_EXISTING_PATIENT_REQUEST,
  payload: {
    startDate,
    endDate,searchKey ,mrnnumber,phone, fullname, patientid, sampleid, searchtype
  }
});

export const fetchPatientSuccess = (Patient) => ({
  type: FETCH_EXISTING_PATIENT_SUCCESS,
  payload: Patient,
});

export const fetchPatientFailure = (error) => ({
  type: FETCH_EXISTING_PATIENT_FAILURE,
  payload: error,
});

export const updateTheGraph = (patientResult, patientFullName) => ({
  type: UPDATE_GRAPH_DATA,
  payload: {
    patientResult,
    patientFullName
  }
})