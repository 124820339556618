export const CREATE_BOOKING_REQUEST = 'CREATE_BOOKING_REQUEST';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAILURE = 'CREATE_BOOKING_FAILURE';
export const CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE';

export const FETCH_USER_BOOKINGS_REQUEST = 'FETCH_USER_BOOKINGS_REQUEST';
export const FETCH_USER_BOOKINGS_SUCCESS = 'FETCH_USER_BOOKINGS_SUCCESS';
export const FETCH_USER_BOOKINGS_FAILURE = 'FETCH_USER_BOOKINGS_FAILURE';

export const FETCH_USER_BOOKED_TIMES_REQUEST = 'FETCH_USER_BOOKED_TIMES_REQUEST';
export const FETCH_USER_BOOKED_TIMES_SUCCESS = 'FETCH_USER_BOOKED_TIMES_SUCCESS';
export const FETCH_USER_BOOKED_TIMES_FAILURE = 'FETCH_USER_BOOKED_TIMES_FAILURE';