import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE,
  CURRENT_USER,
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST,
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS,
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE,
  SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS,
  SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST,
} from "../constants/Auth";

const initState = {
  currentUser: JSON.parse(localStorage.getItem(CURRENT_USER)),
  loading: false,
  onSignUpSuccess : false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN),
  signUpValidationErrors: null,
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.payload.accessToken,
        currentUser: action.payload.currentUser,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
        currentUser: null,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.payload.accessToken,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST: 
    return {
      ...state,
      loading : true
    }

    case SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS : 
    return {
      ...state,
      loading : false,
      onSignUpSuccess : true
    }
    
    case SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE : 
    return {
      ...state,
      loading : false,
      onSignUpSuccess : false
    }
    
    case SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST : 
    return {
        loading : true,
    }
    case SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS : 
    return {
        loading : false,
    }
    case SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE: 
    return {
        loading : false,
    }

    case SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        signUpValidationErrors: action.payload,
      };
    default:
      return state;
  }
};

export default auth;
