import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_SAMPLE_COLLECTED_ORDERS_REQUEST } from "constants/OrderStatus/SampleCollectedOrders";
import { fetchSampleCollectedOrdersFailure, fetchSampleCollectedOrdersSuccess } from "redux/actions";
import SampleCollectedOrders from "services/OrderStatus/SampleCollectedOrders";

export function* onFetchSampleCollectedOrders() {
    yield takeLatest(FETCH_SAMPLE_COLLECTED_ORDERS_REQUEST, function* ({ payload }) {
        try {
            const result = yield call(SampleCollectedOrders.getSampleCollectedOrders, payload);
            if (result.status) {
                yield put(fetchSampleCollectedOrdersSuccess(result.data));
            } else {
                yield put(fetchSampleCollectedOrdersFailure(result.message));
            }
        } catch (error) {
            yield put(fetchSampleCollectedOrdersFailure(error.message));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(onFetchSampleCollectedOrders)
    ]);
}
