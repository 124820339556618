import fetch from 'auth/FetchInterceptor';

const TimeSlotService = {};

TimeSlotService.getTimeSlots = function({ date, branch_id, timezone }) {
  return fetch({
    url: '/time-slots',
    method: 'get',
    params: { date, branch_id, timezone, },
  });
};

export default TimeSlotService;