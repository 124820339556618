import { call, put, takeLatest, all } from 'redux-saga/effects';
import { message } from 'antd';
import BookingService from 'services/BookingService';
import {
  CREATE_BOOKING_REQUEST,
  CANCEL_BOOKING_REQUEST,
  FETCH_USER_BOOKINGS_REQUEST,
  FETCH_USER_BOOKED_TIMES_REQUEST,
} from 'constants/Booking';
import {
  createBookingSuccess,
  createBookingFailure,
  cancelBookingSuccess,
  cancelBookingFailure,
  fetchUserBookingsSuccess,
  fetchUserBookingsFailure,
  fetchUserBookedTimesSuccess,
  fetchUserBookedTimesFailure,
} from '../actions/Booking';

/**
 * Handles the booking creation process.
 * @param {Object} action - The dispatched action containing booking data and an optional callback.
 */
function* createBookingSaga(action) {
  try {
    const response = yield call(BookingService.createBooking, action.payload);

    if (response.status) {
      yield put(createBookingSuccess(response.data));
      message.success('Booking created successfully');

      // Invoke callback with success status and data
      if (action.callback) {
        action.callback(true, response.data);
      }
    } else {
      yield put(createBookingFailure(response.message));
      message.error(response.message);

      // Invoke callback with failure status and error message
      if (action.callback) {
        action.callback(false, response.message);
      }
    }
  } catch (error) {
    yield put(createBookingFailure(error.message));
    message.error('Failed to create booking');

    // Invoke callback with failure status and error message
    if (action.callback) {
      action.callback(false, error.message);
    }
  }
}

/**
 * Handles the booking cancellation process.
 * @param {Object} action - The dispatched action containing booking ID and an optional callback.
 */
function* cancelBookingSaga(action) {
  try {
    const response = yield call(BookingService.cancelBooking, action.payload.bookingId);

    if (response.status) {
      yield put(cancelBookingSuccess(response.payload.bookingId));
      message.success('Booking canceled successfully');

      // Invoke callback with success status and data
      if (action.callback) {
        action.callback(true, response.data);
      }
    } else {
      yield put(cancelBookingFailure(response.message));
      message.error(response.message);

      // Invoke callback with failure status and error message
      if (action.callback) {
        action.callback(false, response.message);
      }
    }
  } catch (error) {
    yield put(cancelBookingFailure(error.message));
    message.error('Failed to cancel booking');

    // Invoke callback with failure status and error message
    if (action.callback) {
      action.callback(false, error.message);
    }
  }
}

/**
 * Handles fetching user bookings.
 * @param {Object} action - The dispatched action containing date and branch_id.
 */
function* fetchUserBookingsSaga(action) {
  try {
    const { date, branch_id } = action.payload;
    const response = yield call(BookingService.fetchUserBookings, date, branch_id);
    if (response.status) {
      yield put(fetchUserBookingsSuccess(response.data));
    } else {
      yield put(fetchUserBookingsFailure(response.message));
      message.error(response.message);
    }
  } catch (error) {
    yield put(fetchUserBookingsFailure(error.message));
    message.error('Failed to fetch user bookings');
  }
}

/**
 * Handles fetching user booked times.
 */
function* fetchUserBookedTimesSaga() {
  try {
    const response = yield call(BookingService.fetchUserBookedTimes);
    if (response.status) {
      yield put(fetchUserBookedTimesSuccess(response.data));
    } else {
      yield put(fetchUserBookedTimesFailure(response.message));
      message.error(response.message);
    }
  } catch (error) {
    yield put(fetchUserBookedTimesFailure(error.message));
    message.error('Failed to fetch booked times');
  }
}

/**
 * Watches for booking-related actions and delegates them to the appropriate sagas.
 */
function* watchBookingSaga() {
  yield takeLatest(CREATE_BOOKING_REQUEST, createBookingSaga);
  yield takeLatest(CANCEL_BOOKING_REQUEST, cancelBookingSaga);
  yield takeLatest(FETCH_USER_BOOKINGS_REQUEST, fetchUserBookingsSaga);
  yield takeLatest(FETCH_USER_BOOKED_TIMES_REQUEST, fetchUserBookedTimesSaga);
}

/**
 * Root saga that aggregates all booking-related sagas.
 */
export default function* rootSaga() {
  yield all([
    watchBookingSaga(),
  ]);
}